<template>
<div>
<bar close="true" title="true" :titleText="$store.state.step.author"/>
<v-content>
	<v-container >
		<v-row>
			<v-col cols="12">
				<h1 class="headline text-center font-weight-black">{{$store.state.step.title}}</h1>
        <h2 class="headline text-center mt-2">- Verso {{$store.state.step.status}} -</h2>
				<h2 class="title font-italic font-weight-black text-center mt-2">{{sentence}}</h2>
			</v-col>
		</v-row>
		<v-row align="center" justify="center">
      <v-col xs="12" sm="6" md="4">
          <v-img :src="$store.state.urlApi+'step/'+$store.state.step.id+'/image?auth='+$store.state.accessToken" />
      </v-col>
		</v-row>
	</v-container>
</v-content>
<footer-button ref="footerButton" redirection="/study-basic" name="Continuar"/>
</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import footerButton from '../components/appComponents/FooterButton.vue'

export default {
  name: 'start-basic',
  components: {
    bar,
    'footer-button' : footerButton
  },
  data() {
    return {
      sentence: ''
    }
  },
  mounted: function(){
    this.$store.state.step.starting = true
    this.$store.state.messageView = false
    this.$store.state.step.successWords = 0
  	if (this.$store.state.step.captions){
      const captions = this.$store.state.step.captions[ this.$store.state.step.status - 1 ]
      let sentence = ''
      for (let i in captions.text){
       sentence += captions.text[i].text+' '
      }
      this.$store.state.step.sentence = sentence
      this.sentence = sentence
      this.$refs.footerButton.enable()
      return
  	}

    this.$store.state.loading = true
    this.$http.get(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/captions',
      { headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '+this.$store.state.accessToken
    }}).then(response => {
    	this.$store.state.step.captions = response.body.calculatedCaptions
		  this.$store.state.loading = false
    	const captions = this.$store.state.step.captions[ this.$store.state.step.status - 1 ]
    	let sentence = ''
    	for (let i in captions.text){
        sentence += captions.text[i].text+' '
    	}
    	this.$store.state.step.sentence = sentence
      this.sentence = sentence
      this.$refs.footerButton.enable()
    }, response => {
        this.$store.state.loading = false
        this.$store.state.refreshHome = true
        this.$store.state.currentRoute = this.$store.state.errorRedirect
        this.$router.push(this.$store.state.currentRoute)
    });
  }
}
</script>

<style>

</style>
